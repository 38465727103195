import React, { useEffect } from "react";
import "./Modal.scss";

interface ModalProps {
    children: React.ReactNode;
    title: string;
    isInfoDialog: boolean;
    canConfirm?: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export default function Modal(props: ModalProps): React.ReactElement {
    const [canConfirm, setCanConfirm] = React.useState(props.canConfirm !== false);

    useEffect(() => {
        setCanConfirm(props.canConfirm !== false);
    }, [props.canConfirm]);

    useEffect(() => {
        window.addEventListener("keyup", ev => onKeyEscape(ev));

        return () => {
            window.removeEventListener("keyup", ev => onKeyEscape(ev));
        };
    }, []);

    const onKeyEscape = (ev: KeyboardEvent) => {
        if (ev.key === "Escape") {
            props.onCancel();
        }
    };

    return <div className="custom-c-modal-bg grid-center">
        <div className="custom-c-modal flex column gap20 border-r10 b-shadow-large">
            <h2>{props.title}</h2>
            {props.children}

            <div className="action-btn flex gap10">
                <button className="cancel btn flex gap5 align-center border-r5" onClick={props.onCancel}>Annuler</button>
                <button
                    disabled={!canConfirm}
                    className="confirm btn flex gap5 align-center border-r5" onClick={props.onConfirm}>Confirmer</button>
            </div>
        </div>
    </div>;
}