import "./MdEditorInput.scss";
import React, { useEffect, useRef, useState } from "react";
import { htmlToMarkdown, markdownToHtml } from "../../utils/markdown-parser";
import { createPortal } from "react-dom";
import Modal from "../modal/Modal";
import Select from "react-select";
import { FaAlignCenter, FaAlignLeft, FaAlignRight, FaAlignJustify } from "react-icons/fa";

export type Value = {
    label: string;
    content: string;
    examples?: string[];
}

export type Variable = {
    name: string;
    examples?: string[];
    valueType?: string;
    values?: Record<string, Value>;
}

export interface TextareaInputProps {
    value?: string;
    onChange?: (text: string) => void;
    variables?: Record<string, Variable>;
}

type ModelVar = {
    id: string,
    name: string,
    value: string,
    isNewVar: boolean
};

function MdEditorInput({ value, onChange, variables }: TextareaInputProps) {
    const [modalVars, setModalVars] = useState<ModelVar | null>(null);
    const [selectedName, setSelectedName] = useState("");
    const [selectedVar, setSelectedVar] = useState("");
    const [selectedOpt, setSelectedOpt] = useState("");
    const [align, setAlign] = useState("L");    // L, C, J, R
    const iRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!iRef.current || !value) return;
        iRef.current.replaceChildren(...markdownToHtml(value));
    }, []);

    useEffect(() => {
        console.log('modalVars: %o', modalVars);
    }, [modalVars]);

    const onChangeText = (content: NodeListOf<HTMLElement>) => {
        const text = htmlToMarkdown(content);
        console.log(`MD: on-change-text: %o`, text);
        if (onChange) onChange(text);
    };

    const onChangeCall = () => {
        iRef.current && onChangeText(iRef.current.childNodes as NodeListOf<HTMLElement>);
    };

    const onConfirmModal = () => {
        console.log(`MD: onConfirmModal: %o`, modalVars);
        const btn = document.getElementById(modalVars?.id || "");
        if (btn) {
            btn.textContent = selectedName;
            const list = [];
            if (selectedVar) list.push(selectedVar);
            if (selectedOpt) list.push(selectedOpt);
            console.log(`MD: selectedName: ${selectedName}, selectedVar: ${selectedVar}, selectedOpt: ${selectedOpt}, list: %o`, list);

            btn.dataset['var'] = list.join(':');
        } else {
            console.log(`MD: could not find button: %o`, modalVars?.id);
        }
        setModalVars(null);
        onChangeCall();
    };

    const changeAlign = () => {
        const aligns = ['L', 'C', 'J', 'R'];
        const idx = aligns.indexOf(align);
        setAlign(aligns[(idx + 1) % 4]);
    }

    const makeStyle = (style: "b" | "i" | "u") => {
        const selection = window.getSelection();
        if (!selection) {
            console.error("MD: No selection found");
            return;
        }

        if (selection.rangeCount === 0) {
            console.error("MD: No range found");
            return;
        }

        console.log(`MD: range selection count: ${selection.rangeCount}`);

        const selText = selection.toString();
        if (selText.length < 1) {
            console.error("MD: No text selected");
            return;
        }

        const range = selection.getRangeAt(0);
        let containerNode: HTMLElement | null = range.commonAncestorContainer as HTMLElement;
        if (!containerNode) {
            console.error("MD: No node found");
            return;
        }

        if (containerNode.nodeType !== Node.ELEMENT_NODE) {
            containerNode = containerNode.parentElement;
            if (!containerNode) {
                console.error("MD: No parent node found");
                return;
            }
            console.log("MD: Selected node", containerNode);
        }

        const styleEl = document.createElement(style === "b" ? 'strong' : style === "i" ? 'em' : 'span');
        style === "u" && styleEl.classList.add("underline"); // why not 'u', because it's deprecated ?

        switch (containerNode.tagName.toLowerCase()) {
            default:
                console.error(`MD: Unknown tag: ${containerNode.tagName}`);
                break;

            case 'div':
                const content2 = range.extractContents().textContent;
                console.log(`MD:DIV: content2: %o`, content2);
                const textNode = document.createTextNode(content2 || "");
                console.log(`MD:DIV: textNode: %o`, textNode);
                styleEl.appendChild(textNode);
                console.log(`MD:DIV: styleEl: %o`, styleEl);

                range.deleteContents();

                // for all direct children of the div, we need to remove empty p elements
                const children = containerNode.children;
                for (let i = 0; i < children.length; i++) {
                    const p = children[i] as HTMLElement;
                    if (p.tagName !== 'P')
                        continue;
                    const pc = p.childNodes;
                    if (pc.length > 1)
                        continue;
                    if (pc.length === 1) {
                        if (pc[0].nodeType !== Node.TEXT_NODE || pc[0].textContent !== '')
                            continue;
                    }
                    containerNode.removeChild(p);
                }

                const p = document.createElement('p');
                p.appendChild(styleEl);
                range.insertNode(p);
                break;

            case 'p':
                const content = range.cloneContents();
                console.log(`MD:P: content: %o`, content);
                styleEl.append(content);
                console.log(`MD:P: styleEl: %o`, styleEl);

                range.deleteContents();
                range.insertNode(styleEl);
                break;
        }

        selection.removeAllRanges();
        onChangeCall();
    };

    const makeVar = () => {
        const button = document.createElement("button");
        button.textContent = "XXXX";
        button.contentEditable = "false";
        button.className = "var btn";
        button.id = `${new Date().getTime()}-var`;

        console.log(`MD: new button id "${button.id}"`);

        const existingID = document.getElementById(button.id);
        console.log(`MD: existingID: %o`, existingID);

        const selection = window.getSelection();
        if (!selection || selection?.rangeCount === 0) {
            console.error("MD: No selection found");
            return;
        }
        const range = selection.getRangeAt(0);

        // Trim (spaces) the selection ?
        let selectedText = range.toString();
        while (selectedText.startsWith(" ")) {
            selectedText = selectedText.slice(1);
            range.setStart(range.startContainer, range.startOffset + 1);
        }
        while (selectedText.endsWith(" ")) {
            selectedText = selectedText.slice(0, -1);
            range.setEnd(range.endContainer, range.endOffset - 1);
        }
        // update selection with new range
        selection.removeAllRanges();
        selection.addRange(range);
        selectedText = selectedText.trim();

        console.log(`MD: MAKEVAR: selected text: %o`, selectedText);

        if (selectedText.length > 0) {
            button.textContent = selectedText;
            range.deleteContents();
        }

        range.insertNode(button);
        range.setStartAfter(button);
        range.collapse(true);

        onChangeCall();
        setSelectedName(selectedText || "XXXX");
        setSelectedVar("");
        setSelectedOpt("");
        setModalVars({
            id: button.id,
            name: selectedText || "XXXX",
            value: '',
            isNewVar: true,
        });
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // const selection = window.getSelection();
        // if (!selection || selection?.rangeCount === 0) {
        //     return;
        // }
        // console.log(`DC: CLICK: selection: ${selection.anchorOffset}`);

        const target = e.target as HTMLElement;
        if (target.tagName === "BUTTON" && target.classList.contains("var")) {
            const id = target.textContent;
            const value = target.dataset['var'] || "";
            console.log(`MD: var clicked: %o`, id);
            console.log(`MD: var clicked: %o`, value);
            setSelectedName(target.textContent || "");
            setSelectedVar(value.split(':')[0]);
            setSelectedOpt(value.split(':')[1]);
            setModalVars({
                id: target.id,
                name: target.textContent || "",
                value: value,
                isNewVar: false,
            });
        }
    };

    const handleDblClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const selection = window.getSelection();
        if (!selection || selection?.rangeCount === 0) {
            return;
        }
        console.log(`DC: DOUBLECLICK: selection: ${selection.anchorOffset}`);

        const range = selection.getRangeAt(0);
        const startNode = range.startContainer;
        const endNode = range.endContainer;
        if (startNode !== endNode) {
            console.log(`DC: startNode !== endNode`);
            return;
        }
        if (!startNode || startNode.nodeType !== Node.TEXT_NODE) {
            console.log(`DC: startNode/endNode is not TEXT_NODE`);
            return;
        }

        let startOffset = range.startOffset;
        let endOffset = range.endOffset;

        const textContent = startNode.textContent || "";

        // remove heading spaces
        const orginalStartOffset = startOffset;
        while (textContent[startOffset] === " ") { 
            startOffset++;
        }

        // remove trailing spaces
        const orginalEndOffset = endOffset;
        while (textContent[endOffset-1] === " ") {
            endOffset--;
        }

        if (startOffset === orginalStartOffset && endOffset === orginalEndOffset) {
            console.log(`DC: no spaces found`);
            return;
        }

        console.log(`DC: startOffset: ${startOffset}/${orginalStartOffset}, endOffset: ${endOffset}/${orginalEndOffset}`);

        // Create a new range and select it
        range.setStart(startNode, startOffset);
        range.setEnd(startNode, endOffset);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    const valueType = (selectedVar && variables && variables[selectedVar] && variables[selectedVar].valueType) || "";
    const values: Record<string, Value> = (selectedVar && variables && variables[selectedVar] && variables[selectedVar].values) || {};
    const examples: string[] = 
        (selectedOpt && values && values[selectedOpt] && values[selectedOpt].examples) ||
        (selectedVar && variables && variables[selectedVar] && variables[selectedVar].examples) || [];

    console.log(`MD: modelVars: %o`, modalVars);
    console.log(`MD: selectedVar: %o`, selectedVar);

    return <div className="md-editor">
        {modalVars && variables && createPortal(
            // ${modalVars.id}
            <Modal title={`Choisir une variable`} isInfoDialog={false}
                onCancel={() => {
                    modalVars?.isNewVar && document.getElementById(modalVars?.id || "")?.remove();
                    onChangeCall();
                    setModalVars(null);
                }}
                canConfirm={selectedVar !== "" && (
                    (valueType === "" && selectedOpt === "") ||
                    (valueType !== "" && selectedOpt !== "")
                )} // todo, check if selectedVar also need selectedOpt
                onConfirm={onConfirmModal}>
                <div>
                    <p>Texte : (pour édition)</p>
                    <input
                        type="text"
                        value={selectedName}
                        onChange={e => setSelectedName(e.target.value)}
                    ></input>
                </div>
                <div>
                    <p>Type de la variable : <b>{selectedVar}</b></p>
                    <Select
                        options={Object.keys(variables).map(k => ({ value: k, label: variables[k].name }))}
                        placeholder="Choisir une valeur"
                        value={
                            (selectedVar && variables[selectedVar]) ?
                                ({ value: selectedVar, label: variables[selectedVar].name }) : null
                        }
                        onChange={o => {
                            setSelectedOpt("");
                            setSelectedVar(o?.value || "");
                            console.log(`MD: selectedVar: %o`, o?.value);
                            setModalVars(v => v ? ({
                                ...v,
                                value: o?.value || ""
                            }) : ({
                                id: "", // never used
                                name: "",
                                value: o?.value || "",
                                isNewVar: false
                            }));
                        }}
                    />
                </div>
                {(Object.keys(values).length > 0) && <div className="values">
                    <p>{valueType} : <b>{selectedOpt}</b></p>
                    <Select
                        options={Object.keys(values).map(
                            k => ({
                                value: k,
                                label: `${values[k].label} (${values[k].content})`,
                            })
                        )}
                        placeholder="Choisir une valeur"
                        value={
                            (selectedOpt && values[selectedOpt]) ? ({
                                value: selectedOpt,
                                label: `${values[selectedOpt]?.label || selectedOpt} (${values[selectedOpt]?.content || selectedOpt})`
                            }) : null}
                        onChange={o => {
                            setSelectedOpt(o?.value || "");
                        }}
                    />
                </div>}
                {(examples.length > 0) && <div className="examples">
                    <p>Exemples:</p>
                    <div>
                    <ul>
                        {examples
                            .filter((ex, i) => i < 6)
                            .map((ex, i) => <li key={i}>
                                {ex}
                            </li>)}
                    </ul>
                    </div>
                </div>}
            </Modal>
            , document.body
        )}

        <div
            id="md-editor-buttons"
            className="flex gap5 buttons"
            onMouseDown={e => e.preventDefault()}
        >
            <button className="btn md-b" onClick={() => makeStyle("b")}>A</button>
            <button className="btn md-u" onClick={() => makeStyle("u")}>A</button>
            <button className="btn md-i" onClick={() => makeStyle("i")}>A</button>
            <button className="btn md-v" onClick={() => makeVar()}>V</button>
            <button className="btn md-a" onClick={() => changeAlign()}>
                {align === 'L' ? <FaAlignLeft /> :
                    align === 'C' ? <FaAlignCenter /> :
                        align === 'J' ? <FaAlignJustify /> :
                            <FaAlignRight />}
            </button>
        </div>

        <div
            id="main-md-editor"
            className={`auto-resize flex column gap10`}
            onClick={handleClick}
            onDoubleClick={handleDblClick}
            contentEditable={true}
            spellCheck={false}
            style={{ textAlign: align === 'L' ? 'left' : align === 'C' ? 'center' : align === 'J' ? 'justify' : 'right' }}
            onInput={e => {
                console.log(`MD: onInput: %o`, e.target);
                onChangeText((e.target as HTMLDivElement).childNodes as NodeListOf<HTMLElement>)
            }}
            ref={iRef}
        ></div>
    </div>;
}

export default MdEditorInput;