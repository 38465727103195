import "./ContractsMd.scss";
import React, { useEffect, useState } from "react";
import { API_contracts_enum, ContractData } from "../../api/contracts";
import MdEditorInput, { Value, Variable } from "../../components/inputs/MdEditorInput";
import { useParams } from "react-router-dom";
import { useEvent } from "react-use";
import { api_get_categories, api_get_categories_products, Category, CategoryProduct } from "../../api/products";

// if ?iframe=1 in URL, then we are in iframe
// and should send messages to parent
const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

/*
{
    "choix": {
        "name": "choix de groupement",
        "examples": [
            "groupement 1",
            "groupement 2",
            "groupement 3",
        ],
    },
    "products": {
        "name": "categorie de produits",
        "valueType": "Catégorie de produits",
        "values": {
            "cat1": {"label": "category 1", "content": "c1"},
            "cat2": {"label": "category 2", "content": "c2"},
            "cat3": {"label": "category 3", "content": "c3"},
        },
    },
    "prices": {
        "name": "prix de produits par catégory",
        "valueType": "Catégorie de produits",
        "values": {
            "price1": {"label": "category 1", "content": "293.00"},
            "price2": {"label": "category 2", "content": "400.00"},
            "price3": {"label": "category 3", "content": "720.00"},
        },
    }
}
*/

function ContractsMd() {
    let params = useParams();
    const [contracts, setContracts] = useState<ContractData[] | null>(null);
    const [source, setSource] = useState<Window | null>(null);
    const [parentNotified, setParentNotified] = useState(!inIframe);
    const [products, setProducts] = useState<CategoryProduct[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [variables, setVariables] = useState<Record<string, Variable>>({});

    useEffect(() => {
        api_get_categories_products()
            .then(products => {
                setProducts(products);
            })
            .catch(e => {
                console.error(e);
            })
            ;
        
        api_get_categories()
            .then(categories => {
                setCategories(categories);
            })
            .catch(e => {
                console.error(e);
            })
            ;
    }, [])

    useEffect(() => {
        if (!products || !categories) return;
        const vars: Record<string, Variable> = {
            "choix": {
                name: "choix de groupement",
                examples: products
                    .map(p => p.choix_groupements)
                    .flat()
                    .filter(c => c.trim() !== "")
            },
            "products": {
                name: "categorie de produits",
                valueType: "Catégorie de produits",
                values: categories.reduce((acc, c) => {
                    const id: string = c.id;
                    const value: Value = {
                        label: c.name,
                        content: c.name,
                    };
                    acc[id] = value;
                    return acc;
                }, {} as Record<string, Value>),
            },
            "prices": {
                name: "prix de produits par catégory",
                valueType: "Catégorie de produits",
                values: categories.reduce((acc, c) => {
                    const id: string = c.id;
                    const prod = products
                        .find(p => p.product_category_id === c.id && p.cost !== 0)
                        ;
                    const cost = prod?.cost || 0;
                    const content = prod ? cost.toFixed(2) : "prix non défini";
                    const value: Value = {
                        label: c.name,
                        content,
                        examples: products
                            .filter(p => p.product_category_id === c.id)
                            .map(p => `${p.name} - ${p.cost} ${p.symbol} TTC - ${p.rate}%`),
                    };
                    acc[id] = value;
                    return acc;
                }, {} as Record<string, Value>),
                // examples: categories.map(c => c.name),
            },
        };
        setVariables(vars);
    }, [products, categories]);

    useEvent('message', (ev) => {
        if (typeof ev.data !== "object") return;
        if (ev.data && ev.data.event === "load") {
            console.log(`MD: message: %o`, ev.data);
            ev.source.postMessage({
                event: "ready",
            }, "*");
            setSource(ev.source);
            setParentNotified(true);
        }
    });

    // console.log(`ContractsMd: params = %o`, params);
    useEffect(() => {
        fetchContracts();
    }, []);

    const fetchContracts = async () => {
        try {
            const data = await API_contracts_enum();
            setContracts(data.map((c: ContractData) => ({
                ...c,
                text: c.text, // !debug!: .replace(/SORENIR/g, `{{SORENIR:products:cat1}}`),
            })));
        } catch (e) {
            console.error(e);
        }
    };

    const onChangeMd = (c: ContractData) => (text: string) => {
        setContracts(list => {
            const nl = [...list || []];
            nl.forEach(c2 => {
                if (c2.id === c.id) {
                    c2.text = text;
                }
            });
            return nl;
        });
        // todo: if in iframe, send message to parent
        if (source) {
            source.postMessage({
                event: "update",
                text: text,
            }, "*");
        }
    };

    if (!contracts || !parentNotified) {
        return <>Loading ...</>
    }

    const c = contracts.find(c => c.id === params.contractId);
    if (!c) {
        return <>Contract not found</>
    }

    return <MdEditorInput
        onChange={onChangeMd(c)}
        value={c.text}
        variables={variables}
        />
}

export default ContractsMd;
