// import pkg from '../package.json';

export const HOME = {
    title: `Mandat`,
    path: "/",
}

export const SEARCH = {
    title: "Recherche défunt",
    path: "/deceased/search",
}

export const DECEASED = {
    title: "Contacts du défunt",
    path: "/deceased/:deceasedId",
}

export const DECEASED_EDIT = {
    title: "Modifier le défunt",
    path: "/deceased/:deceasedId/edit",
}

export const CONTACT_EDIT = {
    title: "Éditer le contact",
    path: "/deceased/:deceasedId/contacts/:contactId",
}

export const CONTACT_NEW = {
    title: "Ajouter un contact",
    path: "/deceased/:deceasedId/contacts/new",
}

export const MANDATE = {
    title: "Mandat",
    path: "/deceased/:deceasedId/contacts/:contactId/mandate",
}

export const CONTRACT = {
    title: "Contrat Mandat",
    path: "/deceased/:deceasedId/contacts/:contactId/contract",
}

export const SEPAMANDATE = {
    title: "Mandat de prélèvement SEPA",
    path: "/deceased/:deceasedId/contacts/:contactId/sepamandate",
}

export const STUDYAGREEMENT = {
    title: "Bon pour accord étude",
    path: "/deceased/:deceasedId/contacts/:contactId/studyagreement",
}

export const DIGITALSTOPAGREEMENT = {
    title: "Bon pour accord arrêt numérique",
    path: "/deceased/:deceasedId/contacts/:contactId/digitalstopagreement",
}

export const PENSIONAGREEMENT = {
    title: "Bon pour accord pension de réversion",
    path: "/deceased/:deceasedId/contacts/:contactId/pensionagreement",
}

export const CERTIFICATE = {
    title: "Attestation sur l'honneur",
    path: "/deceased/:deceasedId/contacts/:contactId/certificate",
}

// Dev component / page
export const POWER_BI = {
    title: "Power Bi",
    path: "/other/power_bi"
}

export const POWER_BI_TWO = {
    title: "Power Bi Two",
    path: "/other/power_bi_two"
}

export const CONTRACTS_EDIT = {
    title: "Edition de contrats",
    path: "/contracts/edit",
}

export const CONTRACTS_MD = {
    title: "Contrats",
    path: "/contracts/md/:contractId",
}

// todo: conditional compilation
export const DEBUG_PRODUCTS = {
    title: "Products",
    path: "/debug/products",
}

// todo: conditional compilation
export const DEBUG_REFERERS = {
    title: "Referers",
    path: "/debug/referers",
}

// todo: conditional compilation
export const DEBUG_STORE = {
    title: "Store",
    path: "/debug/store",
}
