import { HttpError } from './error';

export type Product = {
    id: string,
    choix_groupements: string[],
    code: string,
    name: string,
    name_mandat: string,
    desc_mandat: string,
    opportunity_type: string,
    opportunity_category_id: string,
    price: Price,
}

export type Price = {
    gross_amount: number,
    net_amount: number,
    tax_rate: number,
    currency: Currency,
}

export type Currency = {
    code: string,
    name: string,
    symbol: string,
}

export const api_get_products = async (): Promise<Product[]> => {
    const myHeaders = new Headers();
    myHeaders.set('Content-Type', 'application/json');

    const myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'no-store',
    } as RequestInit;

    const myUri = `/api/v1/products/`;
    const myRequest = new Request(myUri, myInit);
    const myResponse = await fetch(myRequest);
    switch (myResponse.status) {
        case 200:
            const res = myResponse.json();
            return res;
    }

    throw new HttpError(
        myResponse.status,
        myResponse.statusText,
        await myResponse.text()
    );
}

export type Category = {
    id: string,
    date_entered: string,
    date_modified: string,
    deleted: boolean,
    name: string,
    description: string,
    eshop: boolean,
    parent_id: string | null,
    woo_id: string | null,
    woo_locked: boolean | null,
}

export const api_get_categories = async (): Promise<Category[]> => {
    const myHeaders = new Headers();
    myHeaders.set('Content-Type', 'application/json');

    const myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'no-store',
    } as RequestInit;

    const myUri = `/api/v1/products_categories`;
    const myRequest = new Request(myUri, myInit);
    const myResponse = await fetch(myRequest);
    switch (myResponse.status) {
        case 200:
            const res = myResponse.json();
            return res;
    }

    throw new HttpError(
        myResponse.status,
        myResponse.statusText,
        await myResponse.text()
    );
}

export type CategoryProduct = {
    id: string
    name: string
    purchase_name?: string
    cost: number
    rate: number
    symbol: string
    choix_groupements: string[]
    product_category_id: string
}

export const api_get_categories_products = async (): Promise<CategoryProduct[]> => {
    const myHeaders = new Headers();
    myHeaders.set('Content-Type', 'application/json');

    const myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'no-store',
    } as RequestInit;

    const myUri = `/api/v1/products_categories/all`;
    const myRequest = new Request(myUri, myInit);
    const myResponse = await fetch(myRequest);
    switch (myResponse.status) {
        case 200:
            const res = myResponse.json();
            return res;
    }

    throw new HttpError(
        myResponse.status,
        myResponse.statusText,
        await myResponse.text()
    );
}
