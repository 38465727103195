export const markdownToHtml = (markdown: string): Node[] => {
    console.log(`MD: markdownToHtml`);

    const nodes: Node[] = [];

    markdown.split('\n').forEach(line => {
        if (line === "") {
            // nodes.push(document.createElement("br"));
            return;
        }

        const lEl = document.createElement("p");
        let cursor = 0;

        const processMatch = (match: RegExpMatchArray, tag: string, className?: string) => {
            // console.log(`markdownToHtml: processMatch: match: %o`, match);
            // console.log(`markdownToHtml: processMatch: tag: %o`, tag);
            // console.log(`markdownToHtml: processMatch: className: %o`, className);

            if (match.index !== undefined) {
                if (match.index > cursor) {
                    lEl.appendChild(document.createTextNode(line.slice(cursor, match.index)));
                }
                const el = document.createElement(tag);
                if (tag === "button") {
                    el.contentEditable = "false";
                    const tmp = match[1].split(':');
                    const tmp2 = tmp[0].split('@');
                    if (tmp2.length === 2) {
                        el.id = tmp2[1];
                    } else {
                        el.id = `${new Date().getTime()}-var`;
                    }
                    el.textContent = tmp2[0];
                    const data = tmp.slice(1).join(':');
                    el.dataset['var'] = data;
                } else {
                    el.textContent = match[1];
                }
                if (className) {
                    el.className = className;
                }
                lEl.appendChild(el);
                cursor = match.index + match[0].length;
            }
        };

        const patterns = [
            {regex: /(?<!_)__([^_]+?)__(?!_)/, tag: 'span', className: 'underline'},
            {regex: /(?<!\*)\*\*([^*]+)\*\*(?!\*)/, tag: 'strong'},
            {regex: /(?<!\*)\*([^*]+?)\*(?!\*)/, tag: 'em'},
            {regex: /(?<!\{)\{\{([^{}]+?)}}(?!\{)/, tag: 'button', className: 'var btn'}
        ];

        let matches: RegExpMatchArray[] = [];
        for (const {regex} of patterns) {
            matches.push(...line.matchAll(new RegExp(regex, "g")));
        }

        matches.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));

        matches.forEach(match => {
            const pattern = patterns.find(({regex}) => regex.test(match[0]));
            if (pattern) {
                processMatch(match, pattern.tag, pattern.className);
            }
        });

        if (cursor < line.length) {
            lEl.appendChild(document.createTextNode(line.slice(cursor)));
        }

        nodes.push(lEl);
    });

    return nodes;
};


export const htmlToMarkdown = (content: NodeListOf<HTMLElement>): string => {
    console.log(`MD: htmlToMarkdown`);

    const text: string[] = [];
    content.forEach(el => {
        if (el.tagName === 'P') {
            let t = "";
            el.childNodes.forEach(el => {
                // @ts-ignore
                const tag: string | undefined = el.tagName;
                if (tag) {
                    const htmlEl = el as HTMLElement;
                    switch (tag) {
                        case "STRONG":
                            t += ("**" + htmlEl.textContent + "**");
                            break;
                        case "EM":
                            t += ("*" + htmlEl.textContent + "*");
                            break;
                        case "SPAN":
                            if (htmlEl.classList.contains("underline"))
                                t += ("__" + htmlEl.textContent + "__");
                            else
                                t += htmlEl.textContent;
                            break;
                        case "BUTTON":
                            if (htmlEl.classList.contains("var")) {
                                const ds = htmlEl.dataset['var'];
                                t += ("{{" + htmlEl.textContent + '@' + htmlEl.id + ':' + ds + "}}");
                            }
                            break;
                    }
                } else {
                    t += el.textContent;
                }
            });
            text.push(t);
        }
    });
    return text.join('\n');
};
