import './App.scss';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CONTRACTS_MD } from './routes';
import React from 'react';
import ContractsMd from "./pages/contracts-md/ContractsMd";

function App() {
    return (
        <Router>
            <Routes>
                <Route path={CONTRACTS_MD.path} element={<ContractsMd />} />
            </Routes>
        </Router>
    );
}

export default App;
