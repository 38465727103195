import {HttpError} from "./error";

export type ContractData = {
    breadcrumb?: any;
    ranking: number;
    parent_id?: string;
    name: string;
    heading: string;
    deleted: boolean;
    date_entered: string;
    date_modified: string;
    created_by: string;
    id: string;
    code: string;
    modified_user_id: string;
    assigned_user_id: string;
    text: string;
}

export const API_contracts_enum = async (): Promise<ContractData[]> => {
    const myHeaders = new Headers();

    const myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'no-store',
    } as RequestInit;

    const myUri = `/api/v1/contrats`;
    const myRequest = new Request(myUri, myInit);
    const myResponse = await fetch(myRequest);
    switch (myResponse.status) {
        case 200:
            return await myResponse.json();
    }

    throw new HttpError(
        myResponse.status,
        myResponse.statusText,
        await myResponse.text()
    );
}