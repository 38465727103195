export class HttpError extends Error {
    code: Number;
    text: String;

    constructor(public statusCode: number, public statusText: string = '', message: string = '') {
        super(message);
        this.code = statusCode;
        this.text = statusText;
        this.message = message;
    }
}
